.navbar {padding: 15px 0;}

.menus li {
    margin: 0;
    float: left;
    padding:15px 35px;
}

header {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
}
.header .container, .header {
    position: relative;
    padding: 0;
}

.header:before {
    content: '';
    width: 4%;
    height: 1px;
    background: rgb(255 255 255 / 30%);
    position: absolute;
    top: 50%;
    left: 0;
}
.header .container:before {
    content: '';
    width: 87%;
    height: 1px;
    background: rgb(255 255 255 / 30%);
    position: absolute;
    top: 50%;
    right: 0;
}
.header .container .row{
    margin: 0;
}
.menus ul:nth-child(2) li a {
    text-transform: uppercase;
    letter-spacing: 7px;
}
.menus ul li  a{
    color: #fff;
}
.pseudo-text-effect {
    position: relative;
    padding: 0;
    overflow: hidden;
    transition: transform 0.5s;
    display: block;
    margin-bottom: 0;
}
  .pseudo-text-effect::after {
    content: attr(data-after);
    display: inline-block;
    transform: translateY(100%);
    position: absolute;
    left: 0;
    transition: inherit;
  }
  .pseudo-text-effect > span {
    display: inline-block;
    transform: translateY(0%);
    transition: inherit;
    white-space: nowrap;
  }
  .pseudo-text-effect:hover > span {
    transform: translateY(-100%);
  }
  .pseudo-text-effect:hover::after {
    transform: translateY(0%);
  }
  
.hemburgur span {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 1px;
    background:#fff;
    transition: .5s ease-in-out;
    float: left;
    position: absolute;
    border-radius: 100%;
}
.hemburgur {
    width: 20px;
    height: 30px;
    cursor: pointer;
    transform: rotate(0deg);
    position: relative;
    float: right;
    z-index: 1;
    right: 0;
    background: transparent;
    border: none;
    padding: 0 5px;
    outline: 0;
}

.hemburgur span:nth-child(1) {
    top: 0;
}

.hemburgur span:nth-child(2) {
    top: 12px;
}

.hemburgur span:nth-child(3) {
    top: 24px;
}

.menus li:last-child {
    padding-right: 0;
}
.fixed {
    -webkit-animation: smoothScroll 1s forwards;
    animation: smoothScroll 1s forwards;
    left: 0;
    position: fixed;
    top: 0;
}
.header.fixed, .effectheader header {
    box-shadow: 0 3px 3px rgb(0 0 0 / 12%);
    width: 100%;
    z-index: 3;
    background: #fff;
    padding: 5px 0;
}
.fixed .navbar, .effectheader header {
    padding: 0;
}
@keyframes smoothScroll {
    0% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}
.fixed .navbar .menus ul li a {
    color: #000;
}

.header.fixed .container:before, .header.fixed:before, .effectheader .header:before, .effectheader .container:before{
    background: rgb(0 0 0 / 30%);
}

.fixed .menus li, .effectheader header .menus li{
    padding-top: 5px;
    padding-bottom: 5px;
}
/* Submenu */
.submenu {
    background: rgb(132 134 136 / 95%);
    height: 0;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 13.8%;
    transition: all .5s ease;
    visibility: hidden;
    padding: 50px 0;
}
.submenuchild {
    display: none;
    float: right;
    width: 36.5%;
}
.smenu li a {   
    display: inline-block;
    font-size: 17px;
    letter-spacing: 3px;
    line-height: 26px;
    padding: 20px 0;
    position: relative;
    color: #fff !important;
}
.toplinks li:hover .submenu {
    height: 50vh;
    opacity: 1;
    visibility: visible;
}
.toplinks li:hover .submenuchild {
    display: flex;
}

.smenu ul li, .smenu ul {
    width: 100%;
    float: left;
}
.menus .smenu ul li a {
    padding: 0;
    transition: ease all 0.5s;
    letter-spacing: 2px !important;
}
.menus .smenu ul li a {
    display: grid;
    letter-spacing: 1px;
    line-height: initial;
}

.menus .smenu ul li a strong {font-family: 'Franklin Gothic Demi';}

.menus .smenu li {padding-left: 0;position: relative;}

.menus .smenu li:before {
    content: '';
    width: 50px;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: 0;
}

.smenu ul li a:before, .menus .smenu li:last-child:before {
    background: inherit;
    opacity: 0;
}
.wrapper a:before {
    content: '';
    width: 0;
    height: 1px;
    background: #fff;
    bottom: 0;
    position: absolute;
    transition: ease all 0.5s;
    left: 10%;
    right: 10%;
}
.menus ul li a.active_class::before, .fixed .navbar .menus ul li a.active_class::before, .wrapper:hover a::before{
    width: 80%;
}
.menus .smenu ul li:hover a {
    color: #ddd !important;
}

.header.fixed .submenu {
    top: 100%;
}
.menus .smenu ul li {
    padding: 15px 0;
}
.fixed .wrapper a:before {
    background: #000;
}
.abouthead {
    width: 100%;
    float: left;
    min-height: 150px;
}

.abouthead header {
    position: relative;
}

.abouthead .menus ul li a {
    color: #000;
}

.abouthead .header .container:before, .abouthead .header:before, .abouthead .menus ul li a.active_class::before, .abouthead .wrapper:hover a::before{
    background: #d5d1d1;
}