@media all and (max-width:1740px){
    .menus li{
        padding: 15px 30px;
    }
    .projectlogo {
        margin-bottom: 15px;
        padding-bottom: 15px;
        width: 90%;
    }
    
    .projectdetail {
        margin-left: 15%;
    }
    
    .projectcont {
        width: 90%;
    }
    
    .projectcont ul li:before {
        height: 1px;
    }

    .details p span, .details ul li {
        margin: 5px 10px 10px 0;
        padding-right: 10px;
        font-size: 18px;
        line-height: 20px;
    }
    
    .details h4, .residancecont h4 {
        font-size: 34px;
        line-height: 40px;
    }
    
    .top-title h2, .approchbanner h3, .slide h2 {
        font-size: 40px;
        line-height: 50px;
    }
       
    .residancecont ul li {
        font-size: 18px;
        line-height: 36px;
        padding: 5px 0;
    }
}
@media all and (max-width:1640px){
    .menus li {
        padding: 12px 20px;
    }
    .title h3, .sectioncont h1{
        font-size: 44px;
        line-height: 50px;
    }
    .title h2 {
        margin-bottom: 32px;
    }    
    p, .ftrbox ul li, .footerform .form ul li input, .projectcont ul li, .enquire .enquirecontainer ul li input {
        font-size: 16px;
        line-height: 26px;
    }
    
    .appcontent p {
        font-size: 19px;
        line-height: 30px;
    }
    
    .appcont h4 {
        font-size: 26px;
        line-height: 36px;
    }
    
    .serviceimg h4, .projectcont h3 {
        font-size: 21px;
        line-height: 28px;
    }
    
    .slide h2:after {
        width: 50px;
        height: 3px;
    }
    body{
        letter-spacing: 0.7px;
        font-size: 15px;
        line-height: 26px;
    }
    .projectcont ul li{
        padding-bottom: 5px;
        margin-bottom: 5px;
    }
    section {
        padding: 50px 0;
    }
    .form h2{
        font-size: 38px;
        line-height: 50px;
    }
    .enquireform ul li input[type=email], .enquireform ul li input[type=tel], .enquireform ul li input[type=text]{
        height: 45px;
    }
}
@media all and (max-width:1480px){
    .menus li {
        padding: 10px 15px;
    }
    .header .container:before {
        width: 82%;
    }
    
    .title h2 {
        font-size: 15px;
    }
    
    [class^=btn-] {
        max-width: 150px;
        height: 45px;
    }
    
    .projectcont ul li:before {
        width: 20px;
    }
    
    .projectcont {
        width: 100%;
    }
    .menus ul:nth-child(2) li a{
        letter-spacing: 5px;
    }
    .header.fixed .container:before, .header.fixed:before {
        top: 57%;
    }
    
    .enquireform2 {
        padding: 80px 0;
    }
    
    .top-title h2, .approchbanner h3, .slide h2 {
        font-size: 36px;
        line-height: 38px;
    }
    .details h4, .residancecont h4 {
        font-size: 30px;
        line-height: 34px;
    }
    .details p span, .details ul li {
        margin: 2px 5px 5px 0px;
        font-size: 15px;
        line-height: 12px;
    }
    .residancecont ul li {
        font-size: 17px;
        line-height: 30px;
    }

.downloadenquire ul li a {
    font-size: 17px;
    letter-spacing: 1.5px;
}
.downloadenquire ul li a {
    font-size: 16px;
    letter-spacing: 1.25px;
    padding: 0;
    line-height: 50px;
}
.jujhargroup .aboutleft {
    width: 40%;
}
.title h3, .sectioncont h1 {
    font-size: 40px;
    line-height: 44px;
}
.jujhargroup {
    padding-bottom: 60px;
}
.menus ul li a {
    font-size: 13px;
}
.menus .smenu ul li a{
    font-size: 15px;
    line-height: 20px;
}
.logo a {
    width: 84%;
    float: left;
    margin: 0 8%;
}

.mini-container .aboutcolumn {
    width: 75%;
}
.downloadenquire ul li {
    margin: 1%;
    width: 25%;
}
.menus .smenu ul li {
    padding: 10px 0;
}
.aboutcont p {
    margin-bottom: 5px;
}

.header.fixed .container:before, .header.fixed:before {
    top: 50%;
}
.appcont h4 {
    font-size: 24px;
    line-height: 28px;
}
.appcontent p {
    font-size: 17px;
    line-height: 26px;
}
.copyrights p {
    font-size: 11px;
    line-height: 30px;
    letter-spacing: 0.3px;
}
.abouthead {
    min-height: 80px;
}
}
@media all and (max-width:1400px){
    .title h3, .sectioncont h1 {
        font-size: 36px;
        line-height: 38px;
    }
    .details h4, .residancecont h4 {
        font-size: 28px;
        line-height: 30px;
    }
    .appcontent {
        padding: 50px 0px;
    }
    .appcont p {
        margin-bottom: 5px;
        line-height: 22px;
    }
.wrap {
    margin-top: 30px;
}
.residancebox {
    width: 70%;
}
.menus ul:nth-child(2) li a {
    letter-spacing: 4px;
}
.menus ul li a {
    font-size: 12px;
}
.title h2 {
    font-size: 13px;
}
p, .ftrbox ul li, .footerform .form ul li input, .projectcont ul li, .enquire .enquirecontainer ul li input {
    font-size: 15px;
    line-height: 22px;
}
[class^=btn-] {
    max-width: 120px;
    height: 40px;
    font-size: 13px;
}
.aboutcont p {
    margin-bottom: 8px;
}
.serviceimg h4, .projectcont h3 {
    font-size: 19px;
    line-height: 24px;
}
.form h2 {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 3px;
}
.enquireform2 {
    padding: 65px 0;
}
.details p span, .details ul li {
    margin: 0px 5px 5px 5px;
    font-size: 14px;
    line-height: 10px;
}
#fp-nav.fp-right ul li {
    padding: 0px 25px;
    letter-spacing: 3px;
}
#fp-nav.fp-right ul li a span {
    font-size: 13px;
    letter-spacing: 2px;
}
.details h4, .residancecont h4 {
    margin-bottom: 10px;
}
.top-title h2, .approchbanner h3, .slide h2 {
    font-size: 30px;
    line-height: 36px;
}
.gallerycontainer {
    width: 80%;
}
.downloadenquire ul li.download a:before {height: 20px;width: 20px;}
.section.profooter.fp-section.fp-table .footer {margin-bottom: 15px;}

.section.profooter.fp-section.fp-table .servicestn {
    margin: 0;
}
.labelcont {
    font-size: 15px;
    letter-spacing: 2.5px;
}
.menus .smenu ul li a {
    letter-spacing: 1px !important;
}
.menus .smenu li:before{
    height: 1px;
    width: 35px;
}
.projectlogo img {
    width: 120px;
}
}
@media all and (max-width:1280px){
    .top-title h2, .approchbanner h3, .slide h2{
        line-height: 32px;
    }
    .projectimg {
        width: 60%;
    }
    
    .projectstn .projectbox:nth-child(even) .projectdetail {
        margin-right: 10%;
    }
    
    .servicestn {
        margin-top: 15px;
    }
    
    .web-container, .mini-container, .appstn {
        width: 90%;
    }
    
    .services:before, .footerform:after {
        width: 80px;
    }
    
    .serviceimg h4, .projectcont h3 {
        font-size: 17px;
        letter-spacing: 1px;
        line-height: 20px;
    }
    .aboutleft {
        width: 40%;
    }
    .enquireform2 {
        padding: 50px 0;
    }
    .labelcont {
        height: 30px;
    }
    
    .countnum, .countnum h3 {
        font-size: 40px;
        line-height: 40px;
    }
    
    .App {
        height: 100px;
    }
}
@media all and (max-width:1160px){

    .projectbox:before, .aboutus:before {
        width: 80px;
    }
    
    .servicebox:before {
        width: 150px;
        height: 120px;
    }
    .logo a {
        width: 70%;
    }
    #services:before {
        width: 70px;
        height: 14px;
    }
    .slide h2:after {
        height: 2px;
        width: 40px;
    }
    .projectdetail {
        margin-left: 13%;
    }
.ftrbox ul li {
    font-size: 13px;
    line-height: 20px;
}
.residancecont ul li {
    font-size: 15px;
    line-height: 26px;
}
.details h4, .residancecont h4 {
    font-size: 26px;
    line-height: 26px;
}
.residancecont ul li:before{
    width: 20px;
}
.top-title h2, .approchbanner h3, .slide h2 {
    line-height: 30px;
    font-size: 24px;
}
.slick-slider button.slick-arrow.slick-prev, .slick-slider button.slick-arrow.slick-next{
    width: 40px;
    height: 40px;
}
}
@media all and (max-width:1030px){
    .menus li {
        padding: 5px  10px;
    }
    
    .menus ul:nth-child(2) li a {
        letter-spacing: 3px;
    }
    
    .web-container {
        width: 100%;
    }
    
    .projectlogo img {
        width: 100px;
    }
    
    .projectdetail {
        margin-left: 10%;
    }
    
    .projectlogo {
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    
    .wrap {
        margin-top: 15px;
    }
    .countnum, .countnum h3 {
        font-size: 40px;
        line-height: 50px;
    }
    
    .labelcont {
        font-size: 14px;
        height: 40px;
    }
    
    .App {
        height: 100px;
    }
}
@media all and (max-width:820px){
    .logo a {
        margin: 0;
    }
    .logo {
        padding: 15px 0;
        position: relative;
        z-index: 1;
    }
    .projectbox:before, .aboutus:before{
        width: 40px;
    }
    .col-8.a-center.d-flex.align-items-end.justify-content-end.p-0 {
        align-items: center !important;
        padding: 0 10px !important;
    }
    .header:before, .header .container:before{
        opacity: 0;
    }
    .desktop-show, .projectbox:before, .aboutus:before, #fp-nav.fp-right, .projectbox:before, .header:before, .header .container:before{
        display: none;
    }
    .mobile-show{
        display: block;
    }
    .menus {
        position: fixed;
        width: 100%;
        float: left;
        height: 100vh;
        background: rgb(0 0 0 / 95%);
        left: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        transform: scale(1, 0);
        transition: ease all 0.5s;
    }
    .wrapper a:before {
        left: 0;
        right: 0;
        width: 50px;
        background-color: #1c1c1c;
        left: 50%;
        transform: translate(-50%);
    }
    .menus ul li a.active_class::before{
        background-color: #a5a4a4;
    }
    .menus ul li {
        padding: 10px 0;
        padding-bottom: 0;
        margin-top: 5px;
    }
    .menus ul, .menus ul li {
        width: 100%;
        float: left;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center !important;
        padding-left: 0;
        padding-right: 0;
        display: flex;
    }
    
.menus ul:nth-child(1) li:last-child a:before {
    opacity: 0;
}
    .fixed .navbar .menus ul li a, .menus ul li.wrapper a, .effectheader header .menus ul li a {color: #fff;}
    
    .menus ul:last-child li a {
        font-size: 26px;
        line-height: 50px;
        font-family: 'Playfair Display', serif;
    }
    
    .menus ul:nth-child(1) li:last-child {
        border-bottom: none;
        padding-top: 0;
    }
    .menus ul:first-child li a {
        font-size: 19px;
        line-height: 40px;
        color: #7e7e7e;
    }
    .navbar.active .menus {
        transform: scale(1);
    }
    .menus ul li a.active_class::before, .fixed .navbar .menus ul li a.active_class::before, .wrapper:hover a::before{
        width: 50px;
    }
    .header .container .row {
        margin: 0 -10px;
    } 
    .navbar, .fixed .logo {
        padding: 0;
    }
    .hemburgur.active span:first-child {
        left: 5px;
        top: 15px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 80%;
    }
    .hemburgur.active span:nth-child(3) {
        left: 5px;
        top: 15px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 80%;
    }
    
    .aboutcolumn {
        flex-wrap: wrap;
        flex-direction: column;
    }
    
    .projectbox, .projectstn .projectbox:nth-child(even) {
        flex-direction: column-reverse;
    }
    
    .projectdetail, .projectimg, .projectstn .projectbox:nth-child(even) .projectdetail, .aboutleft, .jujhargroup .aboutleft {
        margin: 0;
        width: 100%;
    }
    
    .projectstn {
        width: 90%;
        margin: 0 5%;
    }
    
    .servicestn {
        margin: 0;
        flex-wrap: wrap;
    }
    
    #services {
        padding-bottom: 0;
    }
    
    .form {
        width: 60%;
        margin: 0px 20%;
    }
    
    .form h2 {
        margin-bottom: 25px;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 2px;
    }
    
    .footerform .form ul li {
        width: 100%;
        margin: 10px 0;
        border-bottom: solid 1px #858080;
    }
    
    .footerform .form ul li.btn-11 {
        max-width: 100%;
        margin-top: 20px;
    }
    .projectcont h3 {
        font-size: 21px;
        line-height: 30px;
    }

    .serviceimg h4 {
        font-size: 22px;
        line-height: 28px;
    }
    .footerform .form ul li input {
        height: 40px;
    }
    .footer {
        flex-wrap: wrap;
    }
    
    .ftrbox, .ftrbox.address {
        width: 50%;
        padding: 15px 0;
        border-bottom: solid 1px #39393a;
    }
    
    .ftrbox:first-child {
        width: 100%;
        text-align: center;
    }
    
    .ftrbox.address, .ftrbox:last-child {
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .aboutright {
        width: 70%;
    }
    .slider img {
        height: 95vh;
    }
    .slide03.slide h2{
        bottom: 15%;
    }
    .services, .aboutus, .projects, .approch {
        overflow: hidden;
    }
    .projectbox {
        margin-bottom: 50px;
    }

.slider.internal .slide img {
    height: 50vh;
}
.mini-container .aboutcolumn, .mini-container {
    width: 100%;
}
.appcont h4 {
    font-size: 19px;
    line-height: 24px;
    width: 100%;
}
.appcont p{
    line-height: 20px;
}
.appstn, .approch,.projectlogo,.projectcont, .smenu {
    width: 100%;
    float: left;
}
.submenu{
    background-color: rgb(132 134 136);
}
.projectlogo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectcont, .title.text-left, .aboutcont {
    text-align: center;
}

.projectcont ul li:before {
    left: 50%;
    transform: translate(-50%);
}

.aboutcont .wrap, .projectcont .wrap, .submenuchild {
    display: flex;
    align-items: center;
    justify-content: center;
}
.title h3, .sectioncont h1 {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.5px;
}
.aboutcont .wrap {
    margin-top: 30px;
}

.aboutcolumn .img {
    width: 70%;
    margin: 25px 15%;
}
.submenu {
    padding: 0;
    position: absolute;
    top: 100%;
    z-index: 2;
}
.menus ul li{
    position: relative;
}
.submenuchild {
    height: 100%;
    width: 100%;
}
.toplinks li:hover .submenu{
    height: 30vh;
}
.menus .smenu ul li {
    padding: 15px 0;
    margin: 10px 0;
}
.menus .smenu ul li a {
    font-size: 16px;
    line-height: 24px;
}
.navbar.active .hemburgur span:nth-child(1){
    left: 0px;
    top: 15px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 100%;
    height: 2px;
}
.navbar.active .hemburgur span:nth-child(2){
    opacity: 0;
}
.navbar.active .hemburgur span:nth-child(3){
    left: 0px;
    top: 15px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    width: 100%;
    height: 2px;
}
.hemburgur {
    padding: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: end;
}
.gallerycontainer, .residancebox, .projectscont, .enquireform ul li  {
    width: 100%;
}
.downloadenquire ul li {
    width: 40%;
}
.whitelogo {
    width: 65px;
}
.js_logo {
    width: 90px;
}
.enquireform ul {
    flex-wrap: wrap;
}
.enquirecontainer {
    width: 60%;
}

.enquireform h2 {
    font-size: 29px;
    margin-bottom: 15px;
}

.enquireform button.btn-11 {
    max-width: 100%;
    line-height: 50px;
    height: 50px;
}

.enquirecross {
    width: 50px;
    height: 50px;
    font-size: 21px;
}
.effectheader .navbar.active .hemburgur span, .header.fixed .navbar.active .hemburgur span {
    background: #fff;
}
.effectheader .hemburgur span, .header.fixed .hemburgur span {
    background: #000;
}
.slider:before {
    width: 60%;
}

.slider .slick-dots {
    width: 60%;
    display: flex !important;
    bottom: 1.5%;
}

.services button.slick-arrow.slick-prev {
    left: 0;
}

.services button.slick-arrow.slick-next {
    right: 0;
}

.servicebox {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 4%;
}
.aboutbanner img {
    margin: 0px auto;
}
}
@media all and (max-width:640px){
    section{
        padding: 25px 0;
    }
    .appcontent, .appcontent, .appimg {
        width: 100%;
    }
    .loc_details iframe {
        height: 55vh;
    }
    .location .top-title {
        margin-bottom: 30px;
    }
    .location .projectscont {
        top: 58%;
    }
    .loc_details {
        width: 100%;
    }
    .location {
        top:0;
    }
.appstnrow {
    flex-wrap: wrap;
    margin-bottom: 50px;
}
.appcont {
    width: 100%;
    text-align: center;
    margin-top: 15px;
}
.effectheader .logo {
    padding: 5px 0;
}
.effectheader .logo {
    padding: 5px 0;
}

.downarrow span {
    height: 50px;
}

button.proarrow {
    right: inherit;
    left: 50%;
    bottom: 0;
}

.proarrow .downarrow a {
    padding: 0;
}

.projectscont {
    margin-bottom: 3vh;
}

.details h4, .residancecont h4 {
    font-size: 24px;
}

.residanceimg {
    width: 100%;
}

.residancebox {
    flex-wrap: wrap;
    width: 80%;
    height: 75vh;
}

.residancecont {
    width: 100%;
    text-align: center;
}

.residancecont ul li:before {
    left: 50%;
    transform: translate(-50%);
}

#residences .top-title.backcolor {
    top: 10%;
}

.slick-slider button.slick-arrow.slick-prev {
    left: 5%;
}

.gallerycontainer .slick-slider button.slick-arrow.slick-next {right: 5%;}

.sectioncont {
    width: 100%;
}

.App {
    width: 40%;
}

.count .App:nth-child(3):before {
    background: transparent;
    content: '';
}

.fcontactus .ftrbox, .ftrbox.address {
    border-color: #e9e6e6;
}


.services button.slick-arrow.slick-prev {
    left: 0;
}

.services button.slick-arrow.slick-next {
    right: 0;
}
.abouthead .hemburgur span {
    background: #000;
}
.jujhargroup {
    padding-bottom: 30px;
}
}
@media all and (max-width:480px){
    .aboutcont{
        margin-top: 5px;
    }
    .slider img{
        height: 75vh;
    }
    .abouthead {
        min-height: 60px;
    }
    .logo a {
        width: 100%;
    }
    
    .menus ul:last-child li a {
        font-size: 19px;
        letter-spacing: 2px;
        line-height: 40px;
    }
    
    .menus ul:first-child li a {
        font-size: 16px;
        line-height: 30px;
    }
    .logo{
        padding: 10px 0;
    }
    .slider:before {
        width: 80%;
        height: 40px;
    }
    
    .slider .slick-dots {
        width: 80%;
        bottom: 0;
    }
    
    .projectcont h3 {
        font-size: 19px;
        line-height: 34px;
    }
    
    .form {
        width: 80%;
        margin: 0 10%;
    }
    
    .footerform .form ul li input {height: 35px;}
    

    .servicebox:before {
        height: 100px;
        width: 100px;
    }
    
    .aboutcolumn .img {
        width: 100%;
        margin: 10px 0%;
    }
    .title h3, .sectioncont h1 {
        font-size: 24px;
        line-height: 30px;
    }
    .title h2 {
        margin-bottom: 20px;
    }
    .approchbanner h3 {
        width: 90%;
        display: flow-root;
    }
    .ftrbox:first-child img {
        width: 30%;
    }

    .menus .smenu ul li {
        padding: 10px 0;
        margin: 5px 0; 
    }
    .menus ul:first-child li a {
        font-size: 14px;
    }
.toplinks li:hover .submenu {
    height: 40vh;
}
.aboutcont ul{
    margin-top: 25px;
}
.fixed .menus li, .effectheader header .menus li {
    padding: 2px 0;
}
.sectioncont {width: 90%;}
.prologo img {
    width: 65%;
}
.prologo img.whitelogo {
    width: 50px;
}
/* .prologo img.js_logo {
    width: 80px;
} */
.proheader {padding: 10px 0;}

.projenquire button.btn-11 {
    padding: 0 50px;
    font-size: 12px;
    letter-spacing: 1px;
    height: 30px;
    right: -35px;
}

.projenquire .wrap {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: end;
}

.details h4, .residancecont h4 {
    font-size: 21px;
    line-height: 24px;
}
.details {
    width: 90%;
}
.location .details {
    height: 60vh;
}

.details p span, .details ul li {
    padding-right: 5px;
    font-size: 13px;
    line-height: 10px;
}

#residences .top-title.backcolor {
    top: 8%;
}

.projectgal .slick-slider .slick-list, .top-title {
    width: 100%;
}

.gallery.projectgal {
    margin-top: 20vh;
}
.enquireform h2 {
    font-size: 21px;
    margin-bottom: 10px;
}

.enquireform ul li input[type=email], .enquireform ul li input[type=tel], .enquireform ul li input[type=text] {
    height: 35px;
}

.enquireform ul li {
    margin: 8px 0;
}

.enquireform button.btn-11 {
    height: 40px;
}

.projectdetailsbtn{
    top: 60%;
}
.projectscroll img{
    object-position: center;
    height: 92.5vh;
}
.approchbanner, .numberbanner{
    background-attachment: inherit;
}
.numberbanner{
    padding: 50px 0;
}
}
@media all and (max-width:420px){

    .details p span {
        font-size: 11px;
        line-height: 15px;
        margin-bottom: 0;
    }
    .details h4, .residancecont h4 {
        font-size: 19px;
        line-height: 24px;
    }
    .downarrow span {
        height: 30px;
    }
    .downloadenquire ul li {
        width: 70%;
    }
    .residancebox{
        width: 96%;
    }
    .section.profooter.fp-section.fp-table .servicestn .services {
        padding-top: 7vh;
    }
    .serviceimg{
        margin-top: 0;
    }
    .slider img {
        height: 60vh;
    }
    .copyrights p{
        line-height: 40px;
    }
    .ftrbox, .ftrbox.address{
        width: 45%;
    }
}
@media all and (max-width:375px){
    .container{
        padding: 0;
    }
    .ftrbox, .ftrbox.address {
        width: 48%;
    }

    .hemburgur span {
        width: 3px;
        height: 3px;
    }
    
    .hemburgur span:nth-child(2) {
        top: 10px;
    }
    
    .hemburgur span:nth-child(3) {
        top: 20px;
    }
footer {
    padding-top: 20px;
}
.labelcont {
    font-size: 11px;
    height: auto;
}
.countnum, .countnum h3 {
    font-size: 30px;
    line-height: 30px;
}
.App {
    height: auto;
}
.form h2 {
    margin-bottom: 15px;
    font-size: 19px;
    line-height: 24px;
    letter-spacing: 1px;
}
.services:before, .footerform:after {
    width: 60px;
}
.hemburgur {
    width: 25px;
    height: 25px;
}
.services .web-container {
    padding: 0;
}
.img img {
    padding: 25px 0;
}
.downloadenquire ul li a {
    font-size: 14px;
    line-height: 40px;
}
}
@media all and (max-width:340px){
    .top-title h2, .approchbanner h3, .slide h2 {
        line-height: 20px;
        font-size: 18px;
    }
    .details h4, .residancecont h4 {
        font-size: 16px;
        line-height: 22px;
    }
    .title h3, .sectioncont h1 {
        font-size: 19px;
        line-height: 24px;
    }
    .slider img {
        height: 80vh;
    }
    .ftrbox ul li {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.3px;
    }
    .copyrights p{
        font-size: 10px;
    }
    .menus ul:last-child li a {
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 24px;
    }
    .menus ul:first-child li a {
        font-size: 12px;
    }
}